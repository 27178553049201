import React, { useState } from "react";
import Footer from "../../Footer";
import PageLayout from "../../Layout/PageLayout";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { axios } from "../../../http";
import Slider from "react-slick"; 

const Blog3 = () => {
    
      const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 7000,
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
      const settings1 = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 2,
        initialSlide: 0,
        autoplay: false,
        autoplaySpeed: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 2,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
      const settings2 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: false,
        autoplaySpeed: 5000,
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
      const settings8 = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: false,
        centerMode: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
      const settings3 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
    
      const settings4 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
      const settings5 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
      const settings6 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: false,
        autoplaySpeed: 5000,
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
      return (
        <>
          <div>
            <PageLayout />
            <div>
                <section id="banner-slider">
                    <div className="top-banner-slider">
                        <Slider {...settings}>
                            <div className="slide medymate-slide1">                    
                                <div className="container">
                                    <div className="header-content">
                                        <div className="head">
                                            <h2 style={{marginTop:"135px"}}>
                                            <b style={{fontSize:"50px" }}>Our Blogs</b>                                            
                                            </h2>                            
                                        </div>
                                    </div>
                                </div>
                            </div>                    
                        </Slider>
                    </div>
                </section>          
                
                <section id="blog">
                    <div className="container" id="about">
                        <div className="row mb-5">
                          <div className="col-md-12">
                              <h1>
                                Bridging the Gap: The Essential Role of Doctors on Call in Modern Healthcare  
                              </h1>  
                              <p className="des">
                                In today's fast-paced world, accessibility to quality healthcare is paramount. 
                                But what happens when an unexpected illness strikes outside of regular clinic hours? 
                              </p>
                              <p className="des">
                                Enter the <b>doctor on call</b>, a beacon of medical guidance and reassurance readily available when you need it most. 
                              </p>
                              <p className="des">
                                Their role in modern healthcare goes beyond mere convenience; it's a transformative 
                                force shaping the future of patient care.
                              </p>
                          </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-12">
                                <h4>
                                  From Traditional Practices to Digital Door
                                </h4>
                                <p className="des">
                                  Earlier, "doctor on call" implied reaching out to your physician after hours, often with limited availability. 
                                  Today, the landscape has dramatically shifted. 
                                </p>
                                <p className="des">
                                  Telemedicine advancements allow patients to connect with <b>virtual doctors</b> on call instantly, 24/7, through 
                                  convenient video consultations or phone calls. 
                                </p>
                                <p className="des">
                                  This accessibility is a game-changer, especially for:
                                </p>
                                <ul>
                                  <li>
                                    <b>Remote areas:</b> Individuals in rural communities or with limited transportation options can now access quality 
                                    medical consultations without travelling long distances.
                                  </li>
                                  <li>
                                   <b> Busy schedules:</b>Working professionals and caregivers can seek medical advice without disrupting their commitments.
                                  </li>
                                  <li>
                                   <b> Urgent concerns:</b> Minor illnesses, medication questions, or sudden worries can be addressed promptly, 
                                    preventing unnecessary emergency room visits.
                                  </li>
                                </ul>                                
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-12">
                                <h4>
                                  Beyond Convenience: The Expanding Scope of Care
                                </h4>
                                <p className="des">
                                  Doctors on call aren't just for quick fixes. They offer a diverse range of services, including:
                                </p>
                                <ul>
                                  <li>
                                    <b>Initial assessments:</b> They can evaluate health concerns, provide diagnoses, and recommend appropriate 
                                    next steps, including referrals to specialists if needed.
                                  </li>
                                  <li>
                                   <b> Prescription renewals:</b> Ensure continuity of care by refilling essential medications outside of traditional clinic hours.
                                  </li>
                                  <li>
                                    <b>Chronic disease management:</b> Offer ongoing support and guidance for individuals managing 
                                    chronic conditions like diabetes or hypertension.
                                  </li>
                                  <li>
                                    <b>Mental health consultations:</b> Provide initial assessments and referrals for mental health concerns, 
                                    fostering easier access to crucial support.
                                  </li>
                                </ul>                                
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-12">
                                <h4>
                                The Power of Technology
                                </h4>
                                <p className="des">
                                  Modern doctor-on-call services leverage technology to enhance patient care:
                                </p>
                                <ul>
                                  <li>
                                   <b> Electronic health records (EHRs): </b>Doctors have instant access to a patient's medical history, 
                                    allowing for personalized and informed consultations.
                                  </li>
                                  <li>
                                   <b> Secure platforms:</b> Telemedicine platforms ensure patient privacy and data security during 
                                    virtual consultations.
                                  </li>
                                  <li>
                                    <b>Integration with wearables:</b> Doctors can leverage data from wearables like smartwatches to gain 
                                    a deeper understanding of a patient's health status.
                                  </li>
                                </ul>                                
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-12">
                                <h4>
                                  Benefits for Both Patients and Doctors
                                </h4>
                                <p className="des">
                                  Patients aren't the only ones reaping the benefits:
                                </p>
                                <ul>
                                  <li>
                                    <b>Doctors can expand their reach:</b> Telemedicine allows them to connect 
                                    with patients beyond their geographical limitations, offering their expertise to a wider audience.
                                  </li>
                                  <li>
                                   <b> Improved work-life balance:</b> Flexible scheduling options enable doctors to manage their personal lives more effectively.
                                  </li>
                                  <li>
                                    <b>Reduced administrative burden:</b> Online platforms streamline administrative tasks, 
                                    allowing doctors to focus on patient care.
                                  </li>                                  
                                </ul>                                
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-12">
                                <h4>
                                  Looking Ahead: The Future of Doctor on Call Services
                                </h4>
                                <p className="des">
                                  With advancements in technology and evolving healthcare needs, the role of doctors on call 
                                  will continue to transform:
                                </p>
                                <ul>
                                  <li>
                                    <b>Artificial intelligence (AI) integration:</b> AI-powered chatbots can offer preliminary assessments 
                                    and direct patients to the most appropriate resources.
                                  </li>
                                  <li>
                                   <b> Enhanced diagnostics:</b> Remote diagnostic tools, including home-based tests, can be integrated into 
                                    virtual consultations for more accurate diagnoses.
                                  </li> 
                                  <li>
                                    <b>Specialization:</b> We may see specialized doctors on call, catering to specific needs like 
                                    paediatrics or geriatric care.
                                  </li>
                                </ul>                                
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-12">
                                <h4>
                                 A Beacon of Support in Modern Healthcare
                                </h4>
                                <p className="des">
                                  Doctors on call are more than just a convenient solution; they represent a paradigm 
                                  shift in how we access and experience healthcare. 
                                </p>  
                                <p className="des">
                                  They offer readily available guidance, reduce unnecessary emergency room visits, 
                                  and bridge the gap between patients and timely medical support. 
                                </p>                              
                                <p className="des">
                                  As technology continues to evolve, their role will become even more critical in 
                                  ensuring accessible, responsive, and personalized healthcare for all.
                                </p>  
                            </div>
                        </div>
                    </div>
                </section>
              

                <section className="pre-footer">                   
                  <a href="/#about" className="pre-footer-link">About Us</a>
                  <a href="/#about" className="pre-footer-link pre-footer-link-list">Awards</a>
                  <a href="/#about" className="pre-footer-link pre-footer-link-list">Experience</a>
                  <a href="/#about" className="pre-footer-link pre-footer-link-list">Our Mission</a>                   
                </section>
    
             
    
            </div>             
            <Footer />
          </div>
          <ToastContainer />
        </>
      );
}

export default Blog3