import React, { useState } from "react";
import Footer from "../../Footer";
import PageLayout from "../../Layout/PageLayout";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { axios } from "../../../http";
import Slider from "react-slick"; 

const Blog4 = () => {
    
      const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 7000,
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
      const settings1 = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 2,
        initialSlide: 0,
        autoplay: false,
        autoplaySpeed: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 2,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
      const settings2 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: false,
        autoplaySpeed: 5000,
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
      const settings8 = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: false,
        centerMode: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
      const settings3 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
    
      const settings4 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
      const settings5 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
      const settings6 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: false,
        autoplaySpeed: 5000,
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
      return (
        <>
          <div>
            <PageLayout />
            <div>
                <section id="banner-slider">
                    <div className="top-banner-slider">
                        <Slider {...settings}>
                            <div className="slide medymate-slide1">                    
                                <div className="container">
                                    <div className="header-content">
                                        <div className="head">
                                            <h2 style={{marginTop:"135px"}}>
                                            <b style={{fontSize:"50px" }}>Our Blogs</b>                                            
                                            </h2>                            
                                        </div>
                                    </div>
                                </div>
                            </div>                    
                        </Slider>
                    </div>
                </section>          
                
                <section id="blog">
                    <div className="container" id="about">
                        <div className="row mb-5">
                          <div className="col-md-12">
                              <h1>
                              Telemedicine - Revolutionizing Healthcare in India: Bridging the Gap, Reaching Millions 
                              </h1>  
                              <p className="des">
                                Across the vast landscape of India, healthcare access poses a significant challenge. 
                                Geographical barriers, resource scarcity, and the uneven distribution of specialists 
                                leave millions, especially in rural areas, struggling to reach proper medical care. 
                              </p>
                              <p className="des">
                                Enter telemedicine, a revolutionary force transforming the healthcare landscape and 
                                bridging this critical gap.
                              </p>
                          </div>
                        </div>
                        <div className="row mb-5">
                          <div className="col-md-12">
                              <h4>
                                What is Telemedicine?
                              </h4>  
                              <p className="des">
                                Telemedicine is the delivery of healthcare services remotely, leveraging technology 
                                like video conferencing and mobile apps.  
                              </p>
                              <p className="des">
                                Through these virtual consultations, patients in remote areas can connect with doctors 
                                located far away, eliminating the need for tough travel and long wait times. 
                              </p>
                          </div>
                        </div>
                        <div className="row mb-5">
                          <div className="col-md-12">
                              <h4>
                                What is Telemedicine?
                              </h4>  
                              <p className="des">
                                Telemedicine is the delivery of healthcare services remotely, leveraging technology 
                                like video conferencing and mobile apps.  
                              </p>
                              <p className="des">
                                Through these virtual consultations, patients in remote areas can connect with doctors 
                                located far away, eliminating the need for tough travel and long wait times. 
                              </p>
                          </div>
                        </div>
                        <div className="row mb-5">
                          <div className="col-md-12">
                              <h4>
                                Revolutionizing Access to Healthcare:
                              </h4>  
                              <p className="des">
                                The impact of telemedicine in India is profound:
                              </p>
                              <p className="des">
                                1) Accessibility: Telemedicine transcends geographical limitations, empowering people in 
                                remote villages to access specialist consultations they wouldn't otherwise have. 
                              </p>
                              <p className="des">
                                Imagine a pregnant woman in a remote Himalayan village consulting a renowned gynaecologist in Delhi, 
                                or a child with a rare condition connecting with a specialist hundreds of miles away. 
                              </p>
                              <p className="des">
                                This eliminates time-consuming, expensive travel and increases timely interventions.
                              </p>
                              <p className="des">
                                2) Equity: By connecting patients with specialists regardless of location, telemedicine 
                                promotes equitable access to healthcare, even for marginalized communities. 
                              </p>
                              <p className="des">
                                This empowers individuals, especially women and children in remote areas, to seek timely medical 
                                attention for previously neglected conditions.
                              </p>
                              <p className="des">
                                3) Cost-Effectiveness: Telemedicine reduces costs for both patients and healthcare systems. 
                                Eliminating travel expenses for patients and optimizing specialist time through virtual consultations 
                                lead to significant savings. 
                              </p>
                              <p className="des">
                                Telemedicine platforms offer cost-effective solutions for diagnostics and follow-up consultations, 
                                alleviating strain on healthcare infrastructure.
                              </p>
                              <p className="des">
                                4) Convenience: Imagine scheduling a doctor's appointment from the comfort of your home, 
                                eliminating long waits in crowded clinics. 
                              </p>
                              <p className="des">
                                Telemedicine offers unprecedented convenience, especially for individuals with busy schedules, 
                                disabilities, or chronic conditions requiring frequent consultations.
                              </p>
                          </div>
                        </div>
                        <div className="row mb-5">
                          <div className="col-md-12">
                              <h4>
                                The Telemedicine Landscape in India:
                              </h4>  
                              <p className="des">
                                India's telemedicine journey has seen remarkable progress in recent years:
                              </p>
                              <ul>
                                <li>
                                  <b>Government Initiatives:</b> The government has been a key driver, launching initiatives like eSanjeevani, 
                                  a national telemedicine platform offering consultations across various specialities. 
                                  <p className="des">
                                    Ayushman Bharat Health, MedySeva and Wellness Centers are being equipped with telemedicine facilities, 
                                    expanding reach to rural areas.
                                  </p>
                                </li>
                                <li>
                                  <b>Telemedicine Startups:</b> A vibrant ecosystem of telemedicine startups has emerged, offering innovative 
                                  solutions catering to diverse needs. 
                                <p className="des">
                                  Platforms like Practo, Lybrate, and DocsApp, MedySeva connect patients with doctors across specialities, 
                                  facilitating online consultations, e-prescriptions, and medication delivery.
                                </p>
                                </li>
                                <li>
                                  <b>Technological Advancements:</b> Advances in communication technologies, including secure video conferencing, 
                                  electronic medical records, and mobile health apps, 
                                  are further propelling the growth of telemedicine in India.
                                </li>
                              </ul>
                          </div>
                        </div>
                        <div className="row mb-5">
                          <div className="col-md-12">
                              <h4>
                                Challenges and the Road Ahead:
                              </h4>  
                              <p className="des">
                                Despite its potential, telemedicine faces certain challenges in India:
                              </p>
                              <ul>
                                <li>
                                  <b> Digital Divide:</b> Limited internet access and digital literacy, particularly in rural areas, pose obstacles. 
                                  Bridging this gap requires concerted efforts to improve infrastructure and digital literacy initiatives.
                                </li>
                                <li>
                                    <b> Data Security and Privacy:</b> Addressing concerns around data security and patient privacy is 
                                    crucial for building trust and wider adoption. Stringent regulations and robust data security measures are essential.
                                </li>
                                <li>
                                  <b>Regulatory Framework:</b> While evolving, a clearer and more comprehensive regulatory framework is 
                                  needed to address emerging issues and ensure quality care through telemedicine.
                                </li>
                              </ul>
                          </div>
                        </div>
                        <div className="row mb-5">
                          <div className="col-md-12">
                              <h4>
                                Conclusion:
                              </h4>  
                              <p className="des">
                                Telemedicine is revolutionizing healthcare in India, holding immense potential to bridge the gap, 
                                reach millions, and create a more equitable and accessible healthcare system. 
                              </p>
                              <p className="des">
                                By addressing the challenges and fostering continued innovation, India can harness the power of 
                                telemedicine to create a healthier future for all its citizens.
                              </p>
                          </div>
                        </div>
                    </div>
                </section>
              

                <section className="pre-footer">                   
                  <a href="/#about" className="pre-footer-link">About Us</a>
                  <a href="/#about" className="pre-footer-link pre-footer-link-list">Awards</a>
                  <a href="/#about" className="pre-footer-link pre-footer-link-list">Experience</a>
                  <a href="/#about" className="pre-footer-link pre-footer-link-list">Our Mission</a>                   
                </section>
    
             
    
            </div>             
            <Footer />
          </div>
          <ToastContainer />
        </>
      );
}

export default Blog4