import React, { useState } from "react";
import Footer from "../Footer";
import PageLayout from "../Layout/PageLayout";
import ContactUs from "./ContactUs";
import * as Yup from "yup";
import { Formik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { axios } from "../../http";
import Slider from "react-slick";
import VisibilitySensor from "react-visibility-sensor";
import CountUp from "react-countup";
import InstagramEmbed from 'react-instagram-embed';
//import { IGEmbed } from "react-ig-embed";
import { LinkedInEmbed } from "react-social-media-embed";   

const Blog = () => {
    const startone = () => {
        const start1 = document.getElementById("vid1");
        const start2 = document.getElementById("vid2");
        const start3 = document.getElementById("vid3");
        const start4 = document.getElementById("vid4");
        start1.controls = true;
        start2.controls = true;
        start3.controls = true;
        start4.controls = true;
      };
      const closevideo = () => {
        const new11 = document.getElementById("vid1");
        const new12 = document.getElementById("vid2");
        const new13 = document.getElementById("vid3");
        const new14 = document.getElementById("vid4");
        new11.pause();
        new12.pause();
        new13.pause();
        new14.pause();
      };
    
      const [joinTab, setJoinTab] = useState(1);
    
      const navigateToContact = () =>{
        window.location='/#contact-info'
      }

      const HandleDoctorSubmit = async (values) => {
        const res = await axios
          .post(
            `api/contact_us?contactus_type=${"doctor"}&doctor_name=${
              values.doctor_name
            }&doctor_email=${values.doctor_email}&doctor_number=${
              values.doctor_number
            }&doctor_specialization=${values.doctor_specialization}&center_name=${
              values.center_name
            }&center_phone_num=${values.center_phone_num}&center_address=${
              values.center_address
            }&center_email=${values.center_email}`
          )
          .then((res) => {
            console.log(res.data.msg);
            toast(res.data.msg);
          })
          .catch((err) => {
            toast(err.message);
          });
      };
      const HandleCenterSubmit = async (values) => {
        const res = await axios
          .post(
            `api/contact_us?contactus_type=${"center"}&doctor_name=${
              values.doctor_name
            }&doctor_email=${values.doctor_email}&doctor_number=${
              values.doctor_number
            }&doctor_specialization=${values.doctor_specialization}&center_name=${
              values.center_name
            }&center_phone_num=${values.center_phone_num}&center_address=${
              values.center_address
            }&center_email=${values.center_email}`
          )
          .then((res) => {
            console.log(res.data.msg);
            toast(res.data.msg);
          })
          .catch((err) => {
            toast(err.message);
          });
      };
    
      const validationSchema = Yup.object({
        doctor_name: Yup.string().trim().required("Doctor name is required"),
        doctor_email: Yup.string()
          .email()
          .typeError()
          .required("E-mail id required"),
        doctor_number: Yup.string()
          .trim()
          .min(10)
          .max(10)
          .required("Phone number is required"),
        doctor_specialization: Yup.string().required("Specialization is required"),
      });
      const validationSchema1 = Yup.object({
        center_name: Yup.string().trim().required("Name is required"),
        center_email: Yup.string()
          .email()
          .typeError()
          .required("E-mail id required"),
        center_number: Yup.string()
          .trim()
          .min(10)
          .max(10)
          .required("Phone number is required"),
        center_address: Yup.string().required("Address is required"),
      });
    
      //
    
      const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 7000,
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
      const settings1 = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 2,
        initialSlide: 0,
        autoplay: false,
        autoplaySpeed: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 2,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
      const settings2 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: false,
        autoplaySpeed: 5000,
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
      const settings8 = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: false,
        centerMode: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
      const settings3 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
    
      const settings4 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
      const settings5 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
      const settings6 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: false,
        autoplaySpeed: 5000,
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
      return (
        <>
          <div>
            <PageLayout />
            <div>
                <section id="banner-slider">
                    <div className="top-banner-slider">
                        <Slider {...settings}>
                            <div className="slide medymate-slide1">                    
                                <div className="container">
                                    <div className="header-content">
                                        <div className="head">
                                            <h2 style={{marginTop:"135px"}}>
                                            <b style={{fontSize:"50px" }}>Our Blogs</b>                                            
                                            </h2>                            
                                        </div>
                                    </div>
                                </div>
                            </div>                    
                        </Slider>
                    </div>
                </section>          
                
                <section id="blog">
                    <div className="container" id="about">
                        <div className="row">                                                    
                            <div className="col-md-8">
                              <div className="row">  
                                <div className="col-md-4">
                                    <div className="card medymate-card">
                                        <img src="assets/img/medymate/medymind-logo.png" />
                                    </div>
                                </div>
                                <div className="col-md-8">
                                  <div className="des">
                                      <h6><b>Blog 1</b></h6> 
                                      <p className="des">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>                                                                          
                                      <div className="btn-3">
                                      <a href="blog1">Read More</a>
                                      </div>
                                  </div>
                                </div>
                              </div>
                              <hr />
                              <div className="row">  
                                <div className="col-md-4">
                                    <div className="card medymate-card">
                                        <img src="assets/img/medymate/medymind-logo.png" />
                                    </div>
                                </div>
                                <div className="col-md-8">
                                  <div className="des">
                                      <h6><b>Blog 2</b></h6> 
                                      <p className="des">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>                                                                          
                                      <div className="btn-3">
                                      <a href="blog2">Read More</a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr />
                              <div className="row">  
                                <div className="col-md-4">
                                    <div className="card medymate-card">
                                        <img src="assets/img/medymate/medymind-logo.png" />
                                    </div>
                                </div>
                                <div className="col-md-8">
                                  <div className="des">
                                      <h6><b>Blog 3</b></h6> 
                                      <p className="des">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>                                                                          
                                      <div className="btn-3">
                                      <a href="blog3">Read More</a>
                                      </div>
                                  </div>
                                </div>
                              </div>
                              <hr />
                              <div className="row">  
                                <div className="col-md-4">
                                    <div className="card medymate-card">
                                        <img src="assets/img/medymate/medymind-logo.png" />
                                    </div>
                                </div>
                                <div className="col-md-8">
                                  <div className="des">
                                      <h6><b>Blog 4</b></h6> 
                                      <p className="des">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>                                                                          
                                      <div className="btn-3">
                                      <a href="blog4">Read More</a>
                                      </div>
                                  </div>
                                </div>
                              </div>
                              <hr />
                              <div className="row">  
                                <div className="col-md-4">
                                    <div className="card medymate-card">
                                        <img src="assets/img/medymate/medymind-logo.png" />
                                    </div>
                                </div>
                                <div className="col-md-8">
                                  <div className="des">
                                      <h6><b>Blog 5</b></h6> 
                                      <p className="des">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>                                                                          
                                      <div className="btn-3">
                                      <a href="blog5">Read More</a>
                                      </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <h4 className="py-2">Recent Blog</h4>
                              <div className="row my-2">  
                                <div className="col-md-4">
                                    <div className="card ">
                                        <img src="assets/img/medymate/medymind-logo.png" />
                                    </div>
                                </div>
                                <div className="col-md-8">                                  
                                  <div className="">
                                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>                                                                          
                                  </div>
                                </div>
                              </div>
                              <div className="row my-2">  
                                <div className="col-md-4">
                                    <div className="card ">
                                        <img src="assets/img/medymate/medymind-logo.png" />
                                    </div>
                                </div>
                                <div className="col-md-8">                                  
                                  <div className="">
                                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>                                                                          
                                  </div>
                                </div>
                              </div>
                              <div className="row my-2">  
                                <div className="col-md-4">
                                    <div className="card ">
                                        <img src="assets/img/medymate/medymind-logo.png" />
                                    </div>
                                </div>
                                <div className="col-md-8">                                  
                                  <div className="">
                                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>                                                                          
                                  </div>
                                </div>
                              </div>
                              <div className="row my-2">  
                                <div className="col-md-4">
                                    <div className="card ">
                                        <img src="assets/img/medymate/medymind-logo.png" />
                                    </div>
                                </div>
                                <div className="col-md-8">                                  
                                  <div className="">
                                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>                                                                          
                                  </div>
                                </div>
                              </div>
                              <div className="row my-2">  
                                <div className="col-md-4">
                                    <div className="card ">
                                        <img src="assets/img/medymate/medymind-logo.png" />
                                    </div>
                                </div>
                                <div className="col-md-8">                                  
                                  <div className="">
                                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>                                                                          
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </section>
              

                <section className="pre-footer">                   
                  <a href="/#about" className="pre-footer-link">About Us</a>
                  <a href="/#about" className="pre-footer-link pre-footer-link-list">Awards</a>
                  <a href="/#about" className="pre-footer-link pre-footer-link-list">Experience</a>
                  <a href="/#about" className="pre-footer-link pre-footer-link-list">Our Mission</a>                   
                </section>
    
             
    
            </div>             
            <Footer />
          </div>
          <ToastContainer />
        </>
      );
}

export default Blog