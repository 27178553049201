import React from 'react'
import { NavLink, Link, useNavigate } from "react-router-dom";


function Footer() {
    return (
        <>
         
        <div className='to-top-btn'>
            <a href="https://api.whatsapp.com/send/?phone=%2B917566975666&text&type=phone_number&app_absent=0" target="_blank"><img src='assets/img/icons/whatsapp-logo.png' alt="social-logo"/></a>
        </div>
        <div className='to-top-btn2'>
            <a target="_blank" href='https://www.instagram.com/medy.seva/'><img src='assets/img/icons/instagram.png' alt="social-logo"/></a>
            <a target="_blank" href='https://www.linkedin.com/company/medyseva'><img src='assets/img/icons/linkedin.png' alt="social-logo"/></a>
            <a target="_blank" href='https://www.facebook.com/Medyseva/?ref=pages_you_manage'><img src='assets/img/icons/facebook.png' alt="social-logo"/></a>
            <a target="_blank" href='https://twitter.com/medyseva?s=20'><img src='assets/img/icons/twitter.png' alt="social-logo"/></a>
        </div>
            <footer id="footer">
           
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-3 col-sm-6">
                            <div className="foter-img">
                                <img src="assets/img/logo/logo.png" alt="logo"/>
                            </div>
                            <div className='contact-footer-links'>
                                <img href="" src='assets/img/icons/phone.png' alt="phone-logo" />
                              <div>
                                 <span>Contact Us</span>
                                 <h4>+91 75669 75666</h4>
                              </div>
                            </div>
                            <div className='contact-footer-links pl-2'>
                                <img href="" src='assets/img/icons/info.png' alt="info-logo"/>
                              <div>
                                <p>info@medyseva.com</p>
                              </div>
                            </div>
                            <div className='contact-footer-links'>
                                <img href="" src='assets/img/icons/map.png' alt="map-logo"/>
                              <div>
                                 <p>18/A Electronic Complex,
Pardesipura Indore (M.P.)</p>
                              </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 col-sm-6">
                            <div className="footer-links">
                                <h4 className='pb-3'>Quick  Links</h4>
                                <ul>
                                    <li>
                                        <a href="/#about">About Us</a>
                                    </li>
                                    <li>
                                        <a href="/#services">Services</a>
                                    </li>
                                    <li>
                                        <a href="/#team">Team</a>
                                    </li>
                                    <li>
                                        <a href="/#gallery">Gallery</a>
                                    </li>
                                    <li>
                                        <NavLink className="nav_news" to="/news-and-updates">
                                            News
                                        </NavLink>
                                    </li>
                                    <li>
                                        <a href="/#contact-info">Contact Us</a>
                                    </li>
                                    <li>
                                        <a href="/#testi">
                                            Testimonials
                                        </a>
                                    </li>
                                   
                                    
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 col-sm-6">
                            <div className="footer-links">
                            <h4 className='pb-3'>Our Services</h4>
                                <ul>
                                    <li>Neurology</li>
                                    <li>Dermatology</li>
                                    <li>MD Medicine</li>
                                    <li>Pulmonology</li>
                                    <li>Nephrology</li>
                                    <li>Cardiology</li>
                                    <li>Psychiatry</li>
                                    {/* <li>
                                        <a href><i className="fas fa-phone-alt" /> &nbsp; +91 75669 75666</a>
                                    </li>
                                    <li>
                                        <a href><i className="fas fa-envelope" /> &nbsp; info@medyseva.com</a>
                                    </li>
                                    <li>
                                        <div style={{display:"flex"}}>
                                        <div className='location'>
                                        <i class="fas fa-map-marker-alt"></i>
                                        </div>
                                        <div>
                                            <p className='footer_add'>Registered Office:
                                            18A, Electronic Complex,
                                            Pardesipura,
                                            Indore - 452010</p>
                                        </div></div>
                                        
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 col-sm-6">
                            <div className="footer-links">
                            <h4 className='pb-3'>Other Facilities</h4>
                                {/* <h5>Follow Us</h5> */}
                                <ul>
                                    <li>Ambulance</li>
                                    <li>Hospitalization</li>
                                    <li>Laboratory</li>
                                    <li>Homecare</li>
                                    {/* <li>
                                        <a href="https://www.facebook.com/Medyseva/" target="_blank"><i className="fab fa-facebook-f" /></a>
                                    </li>
                                    <li>
                                        <a href="https://mobile.twitter.com/medyseva" target="_blank"><i className="fab fa-twitter" /></a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/company/medyseva/" target="_blank"><i className="fab fa-linkedin-in" /></a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/medy.seva/?utm_medium=copy_link" target="_blank"><i className="fab fa-instagram" /></a>
                                    </li> */}
 <li>
                                        <a href="/shipping-policy">Shipping Policy</a>
                                    </li>
                                    <li>
                                        <a href="/cancellation-and-refund-policy">Refund policy</a>
                                    </li>
                                    <li>
                                        <a href="/terms-and-conditions">Medyseva T&C</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='to-top-btn1'>
            <a onClick={() => {
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }}><img src='assets/img/icons/arrow-up.png' alt="arrowup-logo"/></a>
        </div>
                <div className="copyright-line">
                    Copyright@2024. Medyseva&nbsp;&nbsp;
                    {/* <a href="/terms" style={{color:'white'}}>Terms & Conditions</a>&nbsp;&nbsp; */}
                    <a href="/privacy-policy" style={{color:'white'}}>Privacy Policy</a>
                </div>
            </footer>
        </>
    )
}

export default Footer