import './App.css';
import { Route,Routes} from "react-router-dom";
import HomePage from './Component/HomePage/HomePage';

import Thankyou from './Component/Pages/Thankyou';
import News from './Component/Pages/News';
import Terms from './Component/Pages/Terms';
import Privacy from './Component/Pages/Privacy';
import Medytalk from './Component/Pages/Medytalk';
import Shipping from './Component/Pages/Shipping';
import MedysevaTerms from './Component/Pages/MedysevaTerms';
import ReturnsAndRefund from './Component/Pages/ReturnsAndRefund';
import Medymate from './Component/Pages/Medymate';
import Blog from './Component/Pages/Blog';
import Blog1 from './Component/Pages/Blogs/Blog1';
import Blog2 from './Component/Pages/Blogs/Blog2';
import Blog3 from './Component/Pages/Blogs/Blog3';
import Blog4 from './Component/Pages/Blogs/Blog4';
import Blog5 from './Component/Pages/Blogs/Blog5';
function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/thankyou" element={<Thankyou />} />
        <Route path="/news-and-updates" element={<News />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/medytalk-health-webinars-discussions" element={<Medytalk />} />
        <Route path="/shipping-policy" element={<Shipping/>} />
        <Route path="/terms-and-conditions" element={<MedysevaTerms/>} />
        <Route path="/cancellation-and-refund-policy" element={<ReturnsAndRefund/>} />
        <Route path="/medymate-healthcare-for-Schools-Colleges" element={<Medymate/>} />
        <Route path="/blog" element={<Blog/>} />
        <Route path="/blog1" element={<Blog1/>} />
        <Route path="/blog2" element={<Blog2/>} />
        <Route path="/blog3" element={<Blog3/>} />
        <Route path="/blog4" element={<Blog4/>} />
        <Route path="/blog5" element={<Blog5/>} />
      </Routes>
    </>
  );
}

export default App;
